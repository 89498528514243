import { Controller } from "@hotwired/stimulus"

import { createApp } from "vue"

import MultiselectCheckBoxes from "../../components/admin/MultiselectCheckBoxes.vue"

export default class extends Controller {
  connect() {
    const el = this.element as HTMLElement

    if (el) {
      createApp(MultiselectCheckBoxes, {
        scope: el?.dataset?.scope || "",
        selected: JSON.parse(el?.dataset?.selected || "[]"),
        options: JSON.parse(el?.dataset?.options || "{}")
      }).mount(el)
    }
  }
}
