import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["imageThumbnail", "imageContainer"]

  declare readonly imageThumbnailTargets: HTMLElement[]
  declare readonly imageContainerTargets: HTMLElement[]

  connect() {
    const imageThumbnails = this.imageThumbnailTargets
    imageThumbnails.forEach((imageThumbnail) => {
      imageThumbnail.addEventListener("click", () => {
        this.showImage(imageThumbnail)
      })
    })
  }

  showImage(imageThumbnail) {
    this.imageContainerTargets.forEach((imageContainer) => {
      imageContainer.classList.add("hidden")
    })
    const imageContainer = this.imageContainerTargets.find(
      (imageContainer) =>
        imageContainer.dataset.productImageId ==
        imageThumbnail.dataset.productImageId
    )
    if (imageContainer) {
      imageContainer.classList.remove("hidden")
    }
  }
}
