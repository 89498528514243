import { Controller } from "@hotwired/stimulus"

import { createApp } from "vue"

import Slugify from "../../components/admin/Slugify.vue"

export default class extends Controller {
  connect() {
    const el = this.element as HTMLElement
    if (el) {
      createApp(Slugify, {
        locale: el?.dataset?.locale || "",
        source: el?.dataset?.source || "",
        target: el?.dataset?.target || ""
      }).mount(el)
    }
  }
}
