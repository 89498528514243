import { Controller } from "@hotwired/stimulus"

import { createApp } from "vue"

import Variants from "../../components/admin/Variants.vue"

export default class extends Controller {
  connect() {
    const el = this.element as HTMLElement
    if (el) {
      createApp(Variants, {
        variants: JSON.parse(el?.dataset?.variants || "[]")
      }).mount(el)
    }
  }
}
