import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const el = this.element as HTMLElement
    const submitButton = el.querySelector("button[type='submit']")
    const checkboxes = el.querySelectorAll("input[type=checkbox]")
    if (submitButton && checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", (event) => {
          const checkedCheckboxes = el.querySelectorAll(
            "input[type=checkbox]:checked"
          )
          if (checkedCheckboxes.length === checkboxes.length) {
            submitButton.removeAttribute("disabled")
          } else {
            submitButton.setAttribute("disabled", "disabled")
          }
        })
      })
    }
  }
}
