import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["kindSelect", "minimumRentalPeriodSelect"]

  declare readonly kindSelectTarget: HTMLSelectElement
  declare readonly hasMinimumRentalPeriodSelectTarget: boolean
  declare readonly minimumRentalPeriodSelectTarget: HTMLSelectElement

  connect() {
    if (this.hasMinimumRentalPeriodSelectTarget) {
      this.toggleRentalPeriod()
    }
  }

  toggleRentalPeriod() {
    const kind = this.kindSelectTarget.value
    if (
      this.hasMinimumRentalPeriodSelectTarget &&
      this.minimumRentalPeriodSelectTarget?.parentElement
    ) {
      this.minimumRentalPeriodSelectTarget.parentElement.style.display =
        kind === "buy" ? "none" : "block"
    }
  }
}
