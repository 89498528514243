import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const dino = this.element as HTMLElement
    setTimeout(() => {
      dino.style.translate = "6rem"
    }, 1000)
    setTimeout(() => {
      dino.dataset.active = "true"
    }, 2000)
  }
}
