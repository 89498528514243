import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ["submitPendingIcon", "submitCompleteIcon"]

  declare readonly submitPendingIconTarget: HTMLElement
  declare readonly submitCompleteIconTarget: HTMLElement

  connect() {
    // Debounce the change method
    this.debouncedChange = debounce(this.debouncedChange.bind(this), 750)
  }

  debouncedChange(event) {
    this.change(event)
  }

  change(event) {
    event.preventDefault()
    this.showPendingIcon()

    const form = this.element as HTMLFormElement
    form.requestSubmit()

    setTimeout(() => {
      this.showSuccessIcon()
    }, 500)
  }

  showPendingIcon() {
    this.submitPendingIconTarget.classList.remove("opacity-0")
  }

  showSuccessIcon() {
    this.submitPendingIconTarget.classList.add("opacity-0")
    this.submitCompleteIconTarget.classList.remove("opacity-0")

    setTimeout(() => {
      this.submitCompleteIconTarget.classList.add("opacity-0")
    }, 2000) // Hide the success icon after 2 seconds
  }
}
