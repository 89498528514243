import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  declare itemTargets: HTMLElement[]
  static targets = ["item"]

  connect(): void {
    const firstItem = this.itemTargets[0]
    firstItem.dataset.active = "true"
  }

  toggle(event) {
    this.itemTargets.forEach((item, i) => {
      if (event.params["index"] === i) {
        item.dataset.active = "true"
      } else {
        item.dataset.active = "false"
      }
    })
  }
}
