import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["videoThumbnail", "videoPlayer"]

  declare readonly videoThumbnailTargets: HTMLElement[]
  declare readonly videoPlayerTargets: HTMLElement[]

  connect() {
    const videoThumbnails = this.videoThumbnailTargets
    videoThumbnails.forEach((videoThumbnail) => {
      videoThumbnail.addEventListener("click", () => {
        this.showVideo(videoThumbnail)
      })
    })
  }

  showVideo(videoThumbnail) {
    this.videoPlayerTargets.forEach((videoPlayer) => {
      videoPlayer.classList.add("hidden")
      const muxVideoPlayer = videoPlayer.querySelector("mux-player")
      if (muxVideoPlayer) {
        muxVideoPlayer.pause()
      }
    })
    const videoPlayer = this.videoPlayerTargets.find(
      (videoPlayer) =>
        videoPlayer.dataset.muxPlaybackId ==
        videoThumbnail.dataset.muxPlaybackId
    )
    if (videoPlayer) {
      videoPlayer.classList.remove("hidden")
      const muxVideoPlayer = videoPlayer.querySelector("mux-player")
      if (muxVideoPlayer) {
        muxVideoPlayer.play()
      }
    }
  }
}
