<template>
  <button
    type="button"
    class="flex gap-1 items-center text-sm transition text-gray-400 hover:text-primary-500 mt-7 pt-0.5"
    @click="generate"
  >
    <Sparkles :size="16" />
    Generate
  </button>
</template>

<script setup lang="ts">
import { Sparkles } from "lucide-vue-next"
import slugify from "slugify"

const generate = () => {
  const source = document.querySelector(props.source) as HTMLInputElement
  const target = document.querySelector(props.target) as HTMLInputElement

  if (source?.value && target) {
    target.value = slugify(source.value, {
      locale: "de",
      lower: true,
      strict: true
    })
  }
}

const props = defineProps({
  locale: {
    type: String,
    default: "en"
  },
  source: {
    type: String,
    default: ""
  },
  target: {
    type: String,
    default: ""
  }
})
</script>

<style scoped></style>
