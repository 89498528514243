<template>
  <div>
    <Multiselect
      mode="tags"
      :searchable="true"
      :options="options"
      v-model="selected"
      :classes="{
        container:
          'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded bg-white leading-snug outline-none h-[2.125rem]',
        tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-0 pl-1 pt-1 min-w-0',
        containerActive: 'ring-0',
        option:
          'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3',
        noResults: 'py-2 px-3 text-gray-400 bg-white text-left text-sm',
        tag: 'bg-primary-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0'
      }"
    />

    <input type="hidden" :name="scope" value="" />

    <div v-for="id in selected" :key="id" class="hidden">
      <input type="checkbox" :value="id" :name="scope" v-model="selected" />
      {{ options[id] }}
    </div>
  </div>
</template>

<script setup lang="ts">
import Multiselect from "@vueform/multiselect"
import { PropType, ref, watch } from "vue"

const props = defineProps({
  scope: {
    type: String,
    required: true
  },
  selected: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  options: {
    type: Object as PropType<{ [key: string]: string }>,
    required: true
  }
})

const selected = ref<string[]>(props.selected)

watch(
  () => props.selected,
  (newValue) => {
    selected.value = newValue
  }
)
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped lang="postcss">
.multiselect {
  @apply !border-gray-200 !rounded !h-5;

  :deep(.multiselect-tag) {
    @apply !text-xs;
  }
  :deep(.multiselect-no-results) {
    @apply !text-sm;
  }
  :deep(.multiselect-option) {
    @apply !text-sm;
  }
  :deep(.multiselect-dropdown) {
    @apply !border-gray-200 !rounded-b;
  }
  :deep(.multiselect-option.is-pointed) {
    @apply !bg-gray-100;
  }
}
</style>
