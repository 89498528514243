import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.disableFileUpload()
  }

  disableFileUpload() {
    const editor = this.element

    if (editor) {
      // make h1 default h2
      document.addEventListener("trix-before-initialize", () => {
        window.Trix.config.blockAttributes.heading1 = {
          tagName: "h2",
          terminal: true,
          breakOnReturn: true,
          group: false
        }
      })

      // Remove button and spacer
      editor.addEventListener("trix-initialize", function (e) {
        const fileTools = editor.querySelector(".trix-button-group--file-tools")
        const spacer = editor.querySelector(".trix-button-group-spacer")
        fileTools?.remove()
        spacer?.remove()
      })

      // Dont allow file uploads
      editor.addEventListener("trix-file-accept", (e) => {
        e.preventDefault()
        alert("File uploads are disabled for this editor.")
      })

      // Dont allow images/etc to be pasted
      editor.addEventListener("trix-attachment-add", function (e) {
        e.preventDefault()
        alert("File uploads are disabled for this editor.")
      })
    }
  }
}
