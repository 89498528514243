import { Controller } from "@hotwired/stimulus"

import * as UpChunk from "@mux/upchunk"

import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["fileInput", "uploadButton", "progressWrapper", "progress"]
  fileInputTarget: HTMLInputElement
  uploadButtonTarget: HTMLButtonElement
  progressWrapperTarget: HTMLDivElement
  progressTarget: HTMLDivElement

  connect(): void {}

  async uploadFile() {
    if (this.fileInputTarget.files) {
      const file = this.fileInputTarget?.files[0]

      if (!file) return

      const { upload_id, upload_url } = await this.getDirectUpload()
      if (!upload_id) return
      if (!upload_url) return

      this.fileInputTarget.disabled = true
      this.uploadButtonTarget.disabled = true
      this.uploadButtonTarget.style.opacity = "0.5"
      this.uploadButtonTarget.innerText = "Uploading..."
      this.progressWrapperTarget.classList.remove("hidden")

      const upload = UpChunk.createUpload({
        endpoint: upload_url,
        file: file,
        chunkSize: 5120
      })

      this.fileInputTarget.value = ""

      upload.on("error", (err) => {
        console.error("💥 🙀", err.detail)
      })
      upload.on("progress", (progress) => {
        console.log("Uploaded", progress.detail, "percent of this file.")
        this.setProgress(progress.detail)
      })
      upload.on("success", () => {
        console.log("Wrap it up, we're done here. 👋")
        this.setProgress(100)
        this.finalizeDirectUpload(upload_id)
      })
    }
  }

  async getDirectUpload(): Promise<any> {
    if (this.element?.dataset) {
      const productId = this.element?.dataset.productId
      const url = `/en/admin/products/${productId}/product_videos/create_direct_upload`
      const response = await fetch(url)
      const data = await response.json()
      return data
    }
    return {}
  }

  async finalizeDirectUpload(uploadId): void {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    if (csrfToken && this.element?.dataset) {
      const productId = this.element?.dataset.productId
      const url = `/en/admin/products/${productId}/product_videos/finalize_direct_upload?mux_upload_id=${uploadId}`
      const response = await fetch(url, {
        method: "POST",
        headers: { "X-CSRF-Token": csrfToken }
      })
      if (response.ok) {
        window.setTimeout(() => {
          Turbo.visit(window.location.href)
        }, 2000)
      }
    }
  }

  setProgress(progress: string): void {
    console.log("progress", progress)
    this.progressTarget.style.width = parseInt(progress, 10).toString() + "%"
  }
}
