import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  switchVariant(event) {
    const select = event.currentTarget
    const selected = select.value
    Turbo.visit(selected)
  }
}
