<template>
  <div>
    <div
      class="hidden gap-4"
      v-for="(variant, index) in allVariants"
      :key="index"
    >
      <input
        :value="variant.id"
        :name="'product[variants_attributes][' + index + '][id]'"
      />
      <input
        :value="variant.translations?.en?.name || ''"
        :name="'product[variants_attributes][' + index + '][name_en]'"
      />
      <input
        :value="variant.translations?.de?.name || ''"
        :name="'product[variants_attributes][' + index + '][name_de]'"
      />
      <input
        :value="variant.kind"
        :name="'product[variants_attributes][' + index + '][kind]'"
      />
      <input
        type="checkbox"
        :checked="variant._destroy"
        :name="'product[variants_attributes][' + index + '][_destroy]'"
      />
    </div>

    <div class="flex flex-col gap-4">
      <div class="flex gap-4 items-start h-full">
        <div class="flex flex-col gap-2">
          <div
            v-for="(variant, index) in allVariants"
            :key="index"
            class="flex gap-4 text-sm items-center"
            :class="{ 'opacity-30': variant._destroy === '1' }"
          >
            <div class="flex items-center gap-2">
              <div>
                <Palette :size="16" v-if="variant.kind === 'variant_color'" />
                <Ruler :size="16" v-else-if="variant.kind === 'variant_size'" />
                <Sparkles
                  :size="16"
                  v-else-if="variant.kind === 'variant_material'"
                />
                <ToyBrick
                  :size="16"
                  v-else-if="variant.kind === 'variant_equipment'"
                />
              </div>

              <select
                :name="`variant-${index}-kind`"
                class="!w-40"
                @change="updateVariantKind(variant, $event.target.value)"
              >
                <option
                  v-for="variantKind in variantKinds"
                  :value="variantKind.value"
                  :selected="variant.kind === variantKind.value"
                >
                  {{ variantKind.label }}
                </option>
              </select>
            </div>

            <div class="flex">
              <label>EN</label>
              <input
                placeholder="Name (EN)"
                type="text"
                class="!rounded-l-none"
                v-model="variant.translations.en.name"
                :name="`variant-${index}-name-en`"
              />
            </div>

            <div class="flex">
              <label>DE</label>
              <input
                placeholder="Name (DE)"
                type="text"
                class="!rounded-l-none"
                v-model="variant.translations.de.name"
                :name="`variant-${index}-name-de`"
              />
            </div>

            <button
              type="button"
              @click="toggleVariant(variant)"
              :title="`Remove Variant ${index}`"
            >
              <X :size="16" :stroke-width="2.5" />
            </button>
          </div>
          <div>
            <button
              class="variant-button variant-button-active"
              type="button"
              @click="addVariant"
            >
              Add Variant
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ruler, Palette, Sparkles, X, ToyBrick } from "lucide-vue-next"

import { ref, onMounted, PropType, computed } from "vue"
import { v4 as uuidv4 } from "uuid"

type Kind =
  | "variant_size"
  | "variant_color"
  | "variant_equipment"
  | "variant_material"

type Variant = {
  id?: number
  uuid: string
  _destroy?: "1" | undefined
  kind: Kind
  translations: {
    en: { name: string }
    de: { name: string }
  }
}

type VariantKinds = {
  [key: string]: { value: Kind; label: string }
}

const variantKinds: VariantKinds = {
  variant_size: { label: "Size", value: "variant_size" },
  variant_color: { label: "Color", value: "variant_color" },
  variant_equipment: { label: "Equipment", value: "variant_equipment" },
  variant_material: { label: "Material", value: "variant_material" }
}

const props = defineProps({
  variants: {
    type: Array as PropType<Variant[]>,
    required: true
  }
})

const allVariants = ref<Variant[]>([])

onMounted(() => {
  allVariants.value = props.variants.map((variant) => {
    if (variant?.translations === undefined) {
      variant.translations = {
        en: { name: "" },
        de: { name: "" }
      }
    }

    if (variant?.translations?.en === undefined) {
      variant.translations.en = { name: "" }
    }
    if (variant?.translations?.de === undefined) {
      variant.translations.de = { name: "" }
    }

    return variant
  })
})

const addVariant = () => {
  allVariants.value.push({
    uuid: uuidv4(),
    kind: "variant_size",
    translations: {
      en: { name: "" },
      de: { name: "" }
    },
    _destroy: undefined
  })
}

const toggleVariant = (variant: Variant) => {
  const found = allVariants.value.find(
    (p) =>
      (p.id !== undefined && p.id === variant.id) ||
      (p.uuid !== undefined && p.uuid === variant.uuid)
  )
  if (found) {
    if (found._destroy === "1") {
      found._destroy = undefined
    } else {
      found._destroy = "1"
    }
  }
}

const updateVariantKind = (variant: Variant, variantKind: Kind) => {
  const found = allVariants.value.find(
    (p) =>
      (p.id !== undefined && p.id === variant.id) ||
      (p.uuid !== undefined && p.uuid === variant.uuid)
  )
  if (found) {
    found.kind = variantKind
  }
}
</script>

<style scoped lang="postcss">
.variant-button {
  @apply select-none text-sm transition border px-2 rounded-full border-dashed hover:bg-gray-100;
}

.variant-button-active {
  @apply bg-primary-200 border-solid border-primary-400 hover:bg-primary-300;
}

label {
  @apply border px-1 py-1 rounded-l border-r-0;
}
</style>
