import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["search", "menu"]
  declare searchTarget: HTMLElement
  declare menuTarget: HTMLElement

  connect(): void {}

  toggleSearch(): void {
    this.menuTarget.classList.add("hidden")
    this.searchTarget.classList.toggle("hidden")
  }

  toggleMenu(): void {
    this.searchTarget.classList.add("hidden")
    this.menuTarget.classList.toggle("hidden")
  }
}
